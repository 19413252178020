import { FunctionComponent } from 'react'
import Image from 'next/image'

interface LayoutProps {}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:h-screen bg-blue-light md:sticky top-0 md:w-1/2 lg:w-1/2">
        <div className="h-full flex flex-col p-24">
          <div className="flex justify-between items-center self-start w-full h-64 max-w-3xl mx-auto">
            <div className="h-48 w-150 md:h-64 md:w-200">
              <Image
                layout="responsive"
                height={64}
                width={218}
                src="/nsf-logo.svg"
                alt="Norges Sjakkforbund logo"
              />
            </div>
            <a
              className="hover:underline text-xs md:text-sm font-medium flex items-center"
              href="https://www.sjakk.no"
            >
              <svg
                className="fill-current inline mr-8 md:mr-8 w-8 md:w-16"
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM19 7L1 7L1 9L19 9L19 7Z"></path>
              </svg>
              Tilbake til Sjakk.no
            </a>
          </div>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className="h-full hidden md:block"
            src="/sjakkbrett.svg"
            alt="Illustrasjon av sjakkspill"
          />
        </div>
      </div>
      <div className="flex flex-col md:w-1/2 lg:w-1/2 p-24 pt-32 max-w-3xl mx-auto">
        {children}
      </div>
    </div>
  )
}

export default Layout
