import Consented from '@/components/Consented'
import Layout from '@/components/Layout'
import MembershipTable from '@/components/MembershipTable'
import { Formik, Form, Field } from 'formik'
import type { NextPageContext } from 'next'
import { getSession, signOut, useSession } from 'next-auth/react'
import Head from 'next/head'
import React from 'react'
import useSWR from 'swr'
import { ComponentWithAuth } from 'types/auth'
import { resloveStatus } from 'utils/helper'

const fetcher = (url: string) => fetch(url).then((r) => r.json())

const Home: ComponentWithAuth = () => {
  const { data: session } = useSession()
  const { data: member, error } = useSWR('/api/member', fetcher)
  // console.log(error)

  const loading = !member && !error

  const saveSettings = async (values: { marketingConsentAt: boolean }) => {
    const timestamp = new Date().toISOString()
    const response = await fetch(`/api/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        marketingConsentAt: values.marketingConsentAt ? timestamp : null,
      }),
    })
    await response.json()
  }

  return (
    <div>
      <Head>
        <title>Sjakk.no - Min side</title>
        <meta name="description" content="Sjakk.no - Min side" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout>
        <div className="flex justify-between w-full items-center">
          <h1 className="font-serif text-2xl md:text-4xl">Min side</h1>
          <button
            className="z-0 flex items-center px-20 pt-6 pb-6 font-medium transition duration-150 rounded-full bg-orange-dark text-orange-light text-xs w-max group hover:bg-orange-medium"
            onClick={() => signOut()}
          >
            Logg ut
          </button>
        </div>
        <Consented>
          <div className="text-green-darker mt-24 p-16 bg-green-light font-normal md:font-medium">
            Velkommen til sjakk.no sine nye sider! Forløpig er det kun mulig å
            se informasjonen vi har registrert på deg som medlem.{' '}
            <a
              href="https://www.sjakk.no/forbund/sjakkontoret"
              className="underline hover:underline hover:text-green-dark"
            >
              Hvis noe ser feil ut, ta kontakt så hjelper vi deg!
            </a>
          </div>
          {loading ? (
            <div className="mt-48 md:mt-0 flex items-center h-full justify-center">
              <div className="h-24 w-24 mr-8">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-medium"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
              <span>Henter medlemsskap...</span>
            </div>
          ) : (
            <>
              <div className="mt-24 w-full flex flex-col gap-40">
                <div className="w-full flex flex-col">
                  <h2 className="text-md font-medium border-b-2 pb-8">
                    Profil
                  </h2>
                  <table className="table-fixed">
                    <tbody>
                      <tr className="border-b-2">
                        <td className="w-1/2 py-8">Navn</td>
                        <td className="py-8 font-medium">
                          {member?.nsf?.first_name || member?.usf?.first_name}{' '}
                          {member?.nsf?.last_name || member?.usf?.last_name}
                        </td>
                      </tr>
                      <tr className="border-b-2">
                        <td className="py-8">E-post</td>
                        <td className="py-8 font-medium">
                          {session?.user?.email}
                        </td>
                      </tr>
                      <tr className="border-b-2">
                        <td className="py-8">Mobilnummer</td>
                        <td className="py-8 font-medium">
                          {member?.nsf?.mobile || member?.usf?.mobile}
                        </td>
                      </tr>
                      <tr className="border-b-2">
                        <td className="py-8">FIDE id</td>
                        <td className="py-8 font-medium">
                          {member?.nsf?.['fide-id'] || member?.usf?.['fide-id']
                            ? member?.nsf?.['fide-id'] ||
                              member?.usf?.['fide-id']
                            : 'Ikke registrert'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {session?.user.NSFmemberId || session?.user.USFmemberId ? (
                  <>
                    <MembershipTable
                      title="Medlemsskap Norges Sjakkforbund"
                      membership={member?.nsf}
                    />
                    <MembershipTable
                      title="Medlemsskap Ungdommens Sjakkforbund"
                      membership={member?.usf}
                    />
                  </>
                ) : (
                  <div>
                    <h2 className="text-md font-medium border-b-2 pb-8">
                      Medlemsskap
                    </h2>
                    <div className="text-blue-dark mt-24 p-16 bg-blue-light font-normal md:font-medium">
                      Vi fant ikke noe medlemskap knyttet til din e-post. Det
                      kan være fordi du har benyttet en annen e-post ved
                      registering av medlemskap, eller at du ikke er medlem.{' '}
                      <a
                        href="https://www.sjakk.no/forbund/sjakkontoret"
                        className="underline hover:underline hover:text-blue-medium"
                      >
                        Hvis du mener noe er feil, ta kontakt så hjelper vi deg!
                      </a>
                      <a
                        href="https://www.sjakk.no/medlemsskap/blimedlem"
                        className="z-0 flex items-center px-24 pt-12 pb-12 mt-16 font-medium transition duration-150 rounded-full bg-blue-dark text-blue-light text-sm w-max group hover:bg-blue-medium"
                      >
                        Bli medlem
                        <svg
                          className="ml-6 transition duration-150 transform group-hover:translate-x-6"
                          width="19"
                          height="16"
                          viewBox="0 0 19 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"
                            fill="#CFE0FF"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col mt-40">
                <h2 className="text-md font-medium border-b-2 pb-8 mb-8">
                  Kommunikasjon
                </h2>
                <Formik
                  initialValues={{
                    marketingConsentAt: session?.user?.marketingConsentAt
                      ? true
                      : false,
                  }}
                  onSubmit={async (values) => {
                    await saveSettings(values)
                  }}
                >
                  {({ isSubmitting, isValid }) => (
                    <Form>
                      <label htmlFor="marketingConsentAt" className="font-sm">
                        <Field
                          id="marketingConsentAt"
                          className="mr-6 border-2 border-black bg-blue-light/30"
                          type="checkbox"
                          name="marketingConsentAt"
                        />
                        Jeg ønsker å motta nyheter, tips og læringsressurser fra
                        Sjakkforbundet på e-post.
                      </label>
                      <button
                        disabled={isSubmitting || !isValid}
                        type="submit"
                        className="mt-16 flex items-center px-20 pt-6 pb-6 font-medium transition duration-150 rounded-full bg-blue-dark text-blue-light text-xs w-max group hover:bg-blue-medium"
                      >
                        {isSubmitting ? 'Lagrer...' : 'Lagre innstillinger '}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </Consented>
      </Layout>
    </div>
  )
}

export async function getServerSideProps(context: NextPageContext) {
  return {
    props: {
      session: await getSession(context),
    },
  }
}

Home.auth = true

export default Home
