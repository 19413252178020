import { Formik, Form, Field, ErrorMessage } from 'formik'
import { signIn, useSession } from 'next-auth/react'
import React, { FunctionComponent } from 'react'

const Consented: FunctionComponent = ({ children }) => {
  const { data: session } = useSession({ required: true })
  const isConsented = !!session?.user?.serviceConsentAt

  const consent = async (values: {
    serviceConsentAt: boolean
    marketingConsentAt: boolean
  }) => {
    const timestamp = new Date().toISOString()
    const response = await fetch(`/api/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        serviceConsentAt: values.serviceConsentAt ? timestamp : null,
        marketingConsentAt: values.marketingConsentAt ? timestamp : null,
      }),
    })
    await response.json()
    // To get the new session we simply reload the page
    window.location.reload()
  }

  if (isConsented) {
    return <>{children}</>
  }

  return (
    <div>
      <div className="text-purple-darker mt-24 p-16 bg-purple-light font-normal md:font-medium">
        Du har ikke ikke tidligere logget inn, og må godta sjakk.no sin {''}
        <a
          className="underline hover:underline hover:text-blue-dark"
          href="https://www.sjakk.no/forbund/lover-og-reglementer/personvernerkl%C3%A6ring"
        >
          personvernsærklering og vilkår for bruk
        </a>
      </div>
      <Formik
        initialValues={{
          marketingConsentAt: false,
          serviceConsentAt: false,
        }}
        validateOnMount
        onSubmit={async (values) => {
          await consent(values)
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className="flex flex-col items-start gap-8 mt-32">
              <label htmlFor="serviceConsentAt" className="font-sm">
                <Field
                  id="serviceConsentAt"
                  className="mr-6 border-2 border-black bg-blue-light/30"
                  required
                  type="checkbox"
                  name="serviceConsentAt"
                />
                Jeg godtar{' '}
                <a
                  className="underline hover:underline hover:text-blue-dark"
                  href="https://www.sjakk.no/forbund/lover-og-reglementer/personvernerkl%C3%A6ring"
                >
                  vilkårene
                </a>{' '}
                til denne tjenesten
              </label>
              <ErrorMessage name="marketingConsentAt" />
              <label htmlFor="marketingConsentAt" className="font-sm">
                <Field
                  id="marketingConsentAt"
                  className="mr-6 border-2 border-black bg-blue-light/30"
                  type="checkbox"
                  name="marketingConsentAt"
                />
                Jeg ønsker å motta nyheter, tips og læringsressurser fra
                Sjakkforbundet på e-post.
              </label>
              <ErrorMessage name="marketingConsentAt" />
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="disabled:opacity-25 disabled:cursor-not-allowed flex items-center px-24 pt-12 pb-12 mt-16 font-medium transition duration-150 rounded-full bg-blue-dark text-blue-light text-sm w-max hover:bg-blue-medium"
              >
                {isSubmitting ? 'Sender inn...' : 'Bekreft'}
                <svg
                  className="ml-6 transition duration-150 transform group-hover:translate-x-6"
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"
                    fill="#CFE0FF"
                  ></path>
                </svg>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Consented
