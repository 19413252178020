import { FunctionComponent } from 'react'
import { resloveStatus } from 'utils/helper'

interface MembershipTableProps {
  membership: any
  title: string
}

const MembershipTable: FunctionComponent<MembershipTableProps> = ({
  membership,
  title,
}) => {
  if (!membership?.member_id) {
    return null
  }

  return (
    <>
      <div className="w-full flex flex-col">
        <h2 className="text-md font-medium border-b-2 pb-8">{title}</h2>
        {membership?.member_id && (
          <table className="table-fixed">
            <tbody>
              <tr className="border-b-2">
                <td className="w-1/2 py-8">Klubb</td>
                <td className="py-8 font-medium">{membership?.organisation}</td>
              </tr>
              <tr className="border-b-2">
                <td className="py-8">Medlemsskapstatus</td>
                <td className="py-8 font-medium">
                  {resloveStatus(membership?.status)}
                </td>
              </tr>
              <tr className="border-b-2">
                <td className="py-8">Medlemsnummer</td>
                <td className="py-8 font-medium">{membership?.member_id}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <div className="w-full flex flex-col">
        <h2 className="text-sm font-medium border-b-2 pb-8">FIDE ratings</h2>
        {membership?.['fide-rating-standard'] ||
        membership?.['fide-rating-hurtig'] ||
        membership?.['fide-rating-lyn'] ? (
          <table className="table-fixed">
            <tbody>
              <tr className="border-b-2">
                <td className="w-1/2 py-8">Standard</td>
                <td className="py-8 font-medium">
                  {membership?.['fide-rating-standard']}
                </td>
              </tr>
              <tr className="border-b-2">
                <td className="py-8">Hurtig</td>
                <td className="py-8 font-medium">
                  {membership?.['fide-rating-hurtig']}
                </td>
              </tr>
              <tr className="border-b-2">
                <td className="py-8">Lyn</td>
                <td className="py-8 font-medium">
                  {membership?.['fide-rating-lyn']}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className="text-purple-darker mt-24 p-16 bg-purple-light font-normal md:font-medium">
            Ingen ratings registert
          </div>
        )}
      </div>

      <div className="w-full flex flex-col">
        <h2 className="text-sm font-medium border-b-2 pb-8">
          Norges Grand Prix klasser
        </h2>
        <table className="table-fixed">
          <tbody>
            <tr className="border-b-2">
              <td className="py-8 w-1/2">Standard</td>
              <td className="py-8 font-medium">
                {membership?.['ngp-standard']}
              </td>
            </tr>
            <tr className="border-b-2">
              <td className="py-8">Hurtig</td>
              <td className="py-8 font-medium">{membership?.['ngp-hurtig']}</td>
            </tr>
            <tr className="border-b-2">
              <td className="py-8">Lyn</td>
              <td className="py-8 font-medium">{membership?.['ngp-lyn']}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full flex flex-col">
        <h2 className="text-sm font-medium border-b-2 pb-8">Norsk Sjakkblad</h2>
        <table className="table-fixed">
          <tbody>
            <tr className="border-b-2">
              <td className="py-8 w-1/2">Format</td>
              <td className="py-8 font-medium">
                {membership?.['sjakkblad-format']}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MembershipTable
